import React, { useRef, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { GET_INVOICE_PROFILE } from '@fingo/lib/graphql';
import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DownloadInvoiceDocumentButton from '@fingo/lib/components/buttons/DownloadInvoiceDocumentButton';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { UploadDocumentButton, CollectionManagerActionButton, RatificationManagerActionButton } from '@fingo/lib/components/buttons';
import { CompanyHasCredentialsCell } from '@fingo/lib/components/cells';
import { AvatarCell } from '@fingo/lib/components/dataGridCells';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIosOutlined from '@mui/icons-material/ArrowForwardIosOutlined';
import ProfileTable from './ProfileTable';
import ProfileCard from './ProfileCards';
import { summaryHeaders, summaryLucilaHeaders, referencesHeaders } from '../../constants/invoice-profile-headers';
import CessionTable from './CessionTable';
import ReplacementTable from './ReplacementTable';
import TraceStatus from './traceStatus';

const invoicesTablesMapper = [
  {
    label: 'Facturas Reemplazadas',
    replacements: 'backwardsReplacementInvoices',
  },
  {
    label: 'Facturas que Reemplaza',
    replacements: 'forwardReplacementInvoices',
  },
  {
    label: 'Facturas Pendiente de Reintegro',
    replacements: 'payingReintegrationsInvoices',
  },
  {
    label: 'Facturas a Reintegrar',
    replacements: 'owedReintegrationsInvoices',
  },
];

const LucilaInvoiceProfile = () => {
  const history = useHistory();
  const cardRef = useRef(null);
  const flipCard = useCallback(() => {
    cardRef.current.toggle();
  }, []);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invoiceId = searchParams.get('invoiceId');
  const { data, loading } = useQuery(
    GET_INVOICE_PROFILE,
    {
      variables: { invoiceId },
    },
  );
  const needsCreditNote = useCallback((invoice) => {
    if (!invoice) {
      return null;
    }
    const rejected = invoice.siiStatus.includes('REJECTED');
    const totalCredit = invoice.creditNotes
      .map((cn) => cn.amountWithIva)
      .reduce((a, b) => a + b, 0);
    return rejected && totalCredit < invoice.amountWithIva;
  }, []);
  const invoiceData = data?.invoice;
  if (loading) {
    return (<CircularProgress sx={{ marginLeft: '50%', marginTop: '25%' }} />
    );
  }
  if (!invoiceData) return null;
  return (
    <Grid container spacing={1} ml={-1} py={3} px={2}>
      <Grid container item xs={12} alignItems="center">
        <IconButton onClick={history.goBack}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography component="span" variant="h3" color="primary.main">
          {`${invoiceData.dteType.name} ${invoiceData.folio}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ProfileCard
          titleActions={
              [
                {
                  id: 'invoice-documents',
                  title: 'Documentos',
                  icon: <UploadDocumentButton
                    invoiceId={invoiceData.id}
                    showTitle
                    variant="h2"
                    sx={{ ml: '5px', display: { xs: 'none', sm: 'inline', md: 'inline', lg: 'inline' } }}
                  />,
                },
                {
                  id: 'invoice-export-xml',
                  title: 'Descargar XML',
                  icon: <DownloadInvoiceDocumentButton invoice={invoiceData} showTitle sx={{ size: 'small' }} documentType="XML" />,
                },
                {
                  id: 'ratification-manager',
                  title: 'Gestión Ratificación',
                  icon: <RatificationManagerActionButton invoice={invoiceData} />,
                }, {
                  id: 'collection-manager',
                  title: 'Gestión Cobranza',
                  icon: <CollectionManagerActionButton invoice={invoiceData} />,
                },
              ]
            }
        >
          <Grid container alignItems="center" sx={{ marginBottom: '3rem' }}>
            <Grid item xs={12}>
              <ProfileTable
                headersList={summaryHeaders.concat(summaryLucilaHeaders)}
                data={[invoiceData]}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                alignItems="center"
              >
                <Grid item xs={12}>
                  <CompanyHasCredentialsCell
                    company={invoiceData.company}
                    sx={{ maxWidth: 150 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                alignItems="center"
                spacing={1.7}
              >
                <Grid item xs={9}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography sx={{ color: '#323232' }}>
                      Ejecutivo Asignado
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <AvatarCell user={invoiceData.company?.executiveAssigned} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                alignItems="center"
                spacing={1.7}
              >
                <Grid item xs={9}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography sx={{ color: '#323232' }}>
                      Ejecutivo Ratificación
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <AvatarCell user={invoiceData.ratificationmanager?.ratificator} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ProfileCard>
        <Grid container spacing={1} ml={-1} mt={1}>
          {invoicesTablesMapper.map((invoice) => (
            <Grid item xs={6} key={invoice.label}>
              <ProfileCard primaryTitle={invoice.label} key={invoice.label}>
                <ReplacementTable replacements={invoiceData[invoice.replacements]} />
              </ProfileCard>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Flippy
          style={{
            backgroundColor: 'transparent',
            height: '100%',
          }}
          flipOnClick={false}
          ref={cardRef}
        >
          <FrontSide
            style={{
              borderRadius: 18,
              padding: 0,
            }}
          >
            <Stack spacing={2}>
              <ProfileCard
                primaryTitle="Cesiones"
                titleElement={(
                  <IconButton
                    onClick={flipCard}
                    color="primary"
                    size="small"
                    disableRipple
                  >
                    <Typography variant="body1" size="small">
                      Traza
                    </Typography>
                    <ArrowForwardIosOutlined />
                  </IconButton>
                )}
              >
                <CessionTable cessions={invoiceData.cessions} />
              </ProfileCard>
              <ProfileCard
                primaryTitle="Documentos Relacionados"
                customActions={(
                  <>
                    {data && needsCreditNote(invoiceData) && (
                    <Grid paddingX="40px">
                      <Typography variant="caption" color="text.primary">
                        <span role="img" aria-label="eyes">
                          👀
                        </span>{' '}
                        Recuerda <b>emitir la nota de crédito</b> asociada a esta factura y evitar
                        el cobro del iva
                      </Typography>
                    </Grid>
                    )}
                  </>
                  )}
              >
                <ProfileTable
                  data={[...invoiceData.intermediateReferencesFrom,
                    ...invoiceData.intermediateReferencesTo]}
                  headersList={referencesHeaders}
                />
              </ProfileCard>
            </Stack>
          </FrontSide>
          <BackSide
            style={{
              borderRadius: 18,
              padding: 0,
            }}
          >
            <ProfileCard
              primaryTitle="Traza"
              titleElement={(
                <IconButton
                  onClick={flipCard}
                  color="primary"
                  size="small"
                  disableRipple
                >
                  <Typography variant="body1" size="small">
                    Cesiones
                  </Typography>
                  <ArrowForwardIosOutlined />
                </IconButton>
              )}
            >
              <TraceStatus invoice={invoiceData} />
            </ProfileCard>
          </BackSide>
        </Flippy>
      </Grid>
    </Grid>
  );
};

export default LucilaInvoiceProfile;
