import { useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import {
  customPriceDifferenceColumn,
  useInvoicePreColumns,
} from '@fingo/lib/constants';
import dayjs from '@fingo/lib/config/dayjs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { buildInvoicesDataToSimulate } from '../../../helpers';

const FactoringSimulationDataGrid = ({
  invoicesDataToSimulate,
  setInvoicesDataToSimulate,
  columns,
}) => {
  useEffect(() => {
    const initialOffers = invoicesDataToSimulate.map((invoice) => {
      const offer = { invoiceId: invoice.id };
      if (
        invoice.dateToPay
        && dayjs(invoice.dateToPay, 'YYYY-MM-DD', true).isValid()
      ) {
        offer.dateToPay = dayjs(invoice.dateToPay);
      }
      return offer;
    });
    shoppingCartOffersVar(initialOffers);
  }, []);

  const invoicesDateToPay = useReactiveVar(shoppingCartOffersVar);
  useEffect(() => {
    const dataToUpdate = [...invoicesDataToSimulate];
    invoicesDateToPay.forEach((invoice) => {
      const indexToUpdate = dataToUpdate.findIndex(
        (inv) => inv.id === invoice.invoiceId,
      );
      if (invoice.dateToPay) {
        dataToUpdate[indexToUpdate].dateToPay = dayjs(invoice.dateToPay);
        dataToUpdate[indexToUpdate].days = dayjs(invoice.dateToPay).diff(
          dayjs().startOf('day'),
          'days',
        );
      }
    });
    setInvoicesDataToSimulate(buildInvoicesDataToSimulate(dataToUpdate));
  }, [invoicesDateToPay]);
  return (
    <FingoDataGrid
      trackerId="PREOFFERS-MANAGER"
      type="available-factoring"
      rows={invoicesDataToSimulate}
      rowCount={invoicesDataToSimulate.length}
      page={0}
      pageSize={invoicesDataToSimulate.length}
      hideFooter
      serverFilters={false}
      columns={useInvoicePreColumns(customPriceDifferenceColumn())}
      selectionModel={invoicesDataToSimulate.map((inv) => inv.id)}
      parentBoxHeight="250px"
      includeHeaders={columns}
    />
  );
};

FactoringSimulationDataGrid.propTypes = {
  invoicesDataToSimulate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      folio: PropTypes.string,
    }),
  ),
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setInvoicesDataToSimulate: PropTypes.func.isRequired,
};

FactoringSimulationDataGrid.defaultProps = {
  invoicesDataToSimulate: [],
};

export default FactoringSimulationDataGrid;
